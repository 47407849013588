/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import PropTypes from "prop-types";
import { Fragment } from "react";
import * as _ from "lodash";
import imgPlaceHolderChoice from "../../assets/placeholder-choice.svg";

export const OptionGroupCheckWithImages = ({
  currentQuestion,
  handleChange,
  currentCheckValues,
  CurrentOtherValueCheck,
  handleChangeOtherCheck,
}) => {
  const checkItem = (e, item) => {
    console.log(e, currentCheckValues);
    handleChange(e, item);
  };
  const checksArray = internal_id => {
    if (!currentCheckValues.length || !Array.isArray(currentCheckValues))
      return false;
    const findCheck = currentCheckValues.filter(ck => {
      return ck.id === internal_id && ck.checked;
    });
    return findCheck.length > 0;
  };

  const normalChoices = _.filter(currentQuestion.option_choices, el => {
    return el.other === false;
  });

  const otherChoices = _.filter(currentQuestion.option_choices, el => {
    return el.other === true;
  });

  console.log(normalChoices, otherChoices);

  return (
    <FormControl
      component="fieldset"
      css={css`
        margin: 0;
        width: 100%;
        border: 1px solid red;
        .MuiTypography-body1 {
          font-family: "Open Sans", sans-serif;
          font-size: 13px;
          word-break: break-word;
        }
        .MuiFormControlLabel-root,
        .MuiFormControlLabel-label {
          padding-top: 3px;
          width: 100%;
        }
        .MuiFormControlLabel-root {
          margin-bottom: 6px;
        }
        .MuiIconButton-label {
          input[type="radio"] {
            display: none !important;
          }
        }
        .MuiRadio-root {
          padding: 5px 10px;
          padding-right: 12px;
        }
        .MuiCheckbox-root {
          padding: 3px 5px;
          margin-left: 5px;
          margin-right: 7px;
        }
        .MuiSvgIcon-root {
          color: rgba(0, 0, 0, 0.7);
          font-size: 18px;
        }
      `}
    >
      <FormGroup
        className="options"
        name="options"
        css={css`
          display: inline !important;
        `}
      >
        {normalChoices.map(item => {
          return (
            <div
              key={item.internal_id}
              className={`imagesChoices rows${currentQuestion.choices_per_row}`}
              css={css`
                width: calc(93% / ${currentQuestion.choices_per_row});
              `}
            >
              {!item.other && (
                <Fragment>
                  <label htmlFor={`check_${item.id}`}>
                    <img
                      src={
                        item.image_url === null
                          ? imgPlaceHolderChoice
                          : item.image_url
                      }
                      alt="imagChoice"
                      className="imgShow"
                    />
                  </label>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id={`check_${item.id}`}
                        checked={checksArray(item.internal_id)}
                        onChange={e => checkItem(e, item)}
                        // onChange={e => handleChange(e, item)}
                        value={item.internal_id}
                      />
                    }
                    label={
                      <span
                        dangerouslySetInnerHTML={{ __html: item.name }}
                      ></span>
                    }
                  />
                </Fragment>
              )}
            </div>
          );
        })}
        {otherChoices.map(item => {
          return (
            <div
              key={item.internal_id}
              className={`imagesChoices rows${currentQuestion.choices_per_row}`}
              css={css`
                width: calc(93% / ${currentQuestion.choices_per_row});
              `}
            >
              {item.other && item.active && (
                <Fragment>
                  <label htmlFor={`check_${item.id}`}>
                    <img
                      src={
                        item.image_url === null
                          ? imgPlaceHolderChoice
                          : item.image_url
                      }
                      alt="imagChoice"
                      className="imgShow"
                    />
                  </label>
                  <FormControlLabel
                    value={item.internal_id}
                    control={
                      <Checkbox
                        id={`check_${item.id}`}
                        checked={checksArray(item.internal_id)}
                        onChange={e => checkItem(e, item)}
                        // onChange={e => handleChange(e, item)}
                        value={item.internal_id}
                      />
                    }
                    label={
                      <span
                        dangerouslySetInnerHTML={{ __html: item.name }}
                      ></span>
                    }
                  />
                  {Array.isArray(currentCheckValues) &&
                    currentCheckValues.map(el => {
                      if (el.checked) {
                        const otherChecked = currentQuestion.option_choices.find(
                          opt => {
                            return opt.internal_id === el.id;
                          },
                        );

                        if (otherChecked.other === true) {
                          console.log("entro");
                          return (
                            <input
                              css={css`
                                margin-top: 10px;
                              `}
                              className="inputOther"
                              type="text"
                              value={CurrentOtherValueCheck}
                              placeholder={item.placeholder}
                              onChange={e =>
                                handleChangeOtherCheck(e, currentQuestion)
                              }
                            />
                          );
                        }
                      }
                      return null;
                    })}
                </Fragment>
              )}
            </div>
          );
        })}
      </FormGroup>
    </FormControl>
  );
};

OptionGroupCheckWithImages.propTypes = {
  currentQuestion: PropTypes.object,
  currentCheckValues: PropTypes.array,
  handleChange: PropTypes.func.isRequired,
  CurrentOtherValueCheck: PropTypes.string,
  handleChangeOtherCheck: PropTypes.func.isRequired,
};
