/**@jsx jsx */
import { jsx } from "@emotion/core";
import PropTypes from "prop-types";

import * as styles from "./Footer.styled";

const Footer = ({
  designOptions,
  currentQuestion,
  handleClickBackButton,
  questionsPassed,
  handleNextQuestionDisabledRadio,
  handleNextQuestionDisabledCheck,
  nextQuestion,
  ...rest
}) => {
  const getNextButtonClassname = () => {
    return (currentQuestion.required && rest.nextDisabled) ||
      (rest.nextOther && rest.nextDisabled)
      ? "nextDisabled"
      : "";
  };
  return (
    <footer css={styles.footer({ designOptions })}>
      {designOptions.switchBackButton === "true" && questionsPassed > 0 && (
        <button className="buttonBack" onClick={handleClickBackButton}>
          {designOptions.back_button}
        </button>
      )}
      {designOptions.switchNextButton === "true" &&
        currentQuestion.question_type === "radio" && (
          <button
            className={`${getNextButtonClassname()} buttonNext`}
            onClick={() => {
              handleNextQuestionDisabledRadio(currentQuestion);
            }}
        >
            {designOptions.next_button}
          </button>
      )}
      {designOptions.switchNextButton === "false" &&
        currentQuestion.question_type === "radio" &&
        rest.nextOtherAlone && (
          <button
            className={`${rest.nextDisabled ? "nextDisabled" : ""} buttonNext`}
            onClick={() => {
              handleNextQuestionDisabledRadio(currentQuestion);
            }}
          >
            {designOptions.next_button}
        </button>
        )}
      {currentQuestion.question_type !== "radio" && !currentQuestion.required && (
        <button
          className={`${
            rest.nextCheckOther || rest.nextDisabledUrlShort
              ? "nextDisabled"
              : ""
          } buttonNext`}
          onClick={nextQuestion}
        >
          {designOptions.next_button}
        </button>
      )}
      {currentQuestion.question_type !== "radio" && currentQuestion.required && (
        <button
          className={`${
            (currentQuestion.required && rest.nextDisabled) ||
            rest.nextCheckOther
              ? "nextDisabled"
              : ""
          } buttonNext`}
          onClick={() => {
            if (currentQuestion.question_type === "check") {
              handleNextQuestionDisabledCheck();
            } else {
              nextQuestion();
            }
          }}
        >
          {designOptions.next_button}
        </button>
      )}
    </footer>
  );
};

Footer.propTypes = {
  currentQuestion: PropTypes.object,
  designOptions: PropTypes.object,
  isPreview: PropTypes.bool,
};

export default Footer;
