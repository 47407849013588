/**@jsx jsx */
import { jsx } from "@emotion/core";
import { progressBar } from "./ProgressBar.styled";

const ProgressBar = ({ numBar, colorProgressBar, textColorProgressBar }) => {
  return (
    <div css={progressBar(numBar, colorProgressBar, textColorProgressBar)}>
      <div className="mainProgressBar"></div>
    </div>
  );
};

export default ProgressBar;
