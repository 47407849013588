import { css } from "@emotion/core";

export const footer = props => css`
  height: 50px;
  width: 100%;
  position: relative;
  position: absolute;
  width: 100%;
  bottom: 0;
  padding: 0px;
  left: 0;
  button {
    padding: 10px 15px;
    min-width: 100px;
    color: black;
    border-radius: 4px;
    border: none;
    outline: none;

    @media (max-width: 326px) {
      min-width: inherit;
    }

    &.buttonBack {
      float: left;
      background-color: ${props.designOptions.backButtonBgColor};
      color: ${props.designOptions.backButtonTextColor};
      cursor: pointer;

      &:hover {
        opacity: 0.9;
      }
    }
    &.buttonNext {
      float: right;
      background-color: ${props.designOptions.nextButtonBgColor};
      color: ${props.designOptions.nextButtonTextColor};
      cursor: pointer;

      &.nextDisabled {
        pointer-events: none;
        background: #dddddd;
      }

      &:hover {
        opacity: 0.9;
      }
    }
  }
`;
